import React from 'react';
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";

function team() {
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Contact Us" />
            <Container className="py-5">
                <Row className="justify-content-center text-center mb-lg-5 mb-3">
                    <Col lg={12} className="mb-3">
                        <div className="section-title">
                            <div>Contact Us</div>
                            <h3>Contact Us</h3>
                        </div>
                    </Col>
                </Row>
                
                <Row className='contact-fields justify-content-center'>
                    <Col xs={12} className="mb-4">
                        <h3>Leave a message for us</h3>
                        <p>Drop us a line. We wish to solve any issues you find. Please tell us what we can do to help.</p>
                    </Col>
                    <Col lg={7} className="mb-lg-0 mb-5">
                        <Form>
                            <Row>
                                <Col xs={12} sm={6} className="mb-md-4 mb-3">
                                    <input type="text" className='form-control' placeholder='Your name' />
                                </Col>
                                <Col xs={12} sm={6} className="mb-md-4 mb-3">
                                    <input type="text" className='form-control' placeholder='user@example.com' />
                                </Col>
                                <Col xs={12} className="mb-md-4 mb-3">
                                    <input type="text" className='form-control' placeholder='Subject' />
                                </Col>
                                <Col xs={12} className="mb-md-4 mb-3">
                                    <textarea className='form-control' placeholder='Enter your message' rows="8" />
                                </Col>
                            </Row>
                            <button type="submit" className="text-white btn btn-primary">Send message</button>
                        </Form>
                    </Col>
                    <Col lg={5} md={8} sm={10}>
                        <div className="contact-details">
                            <h4>Get in touch</h4>

                            <Table>
                                <tbody>
                                    <tr>
                                        <th>
                                            <div className="d-flex align-items-center">
                                                <svg className='mr-sm-3 mr-2' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.75 11.25V23.4375C3.75 23.6861 3.84877 23.9246 4.02459 24.1004C4.2004 24.2762 4.43886 24.375 4.6875 24.375H25.3125C25.5611 24.375 25.7996 24.2762 25.9754 24.1004C26.1512 23.9246 26.25 23.6861 26.25 23.4375V11.25L15 3.75L3.75 11.25Z" stroke="white" strokeWidth="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M12.9492 17.8125L4.04297 24.1055" stroke="white" strokeWidth="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M25.957 24.1055L17.0508 17.8125" stroke="white" strokeWidth="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M26.25 11.25L17.0508 17.8125H12.9492L3.75 11.25" stroke="white" strokeWidth="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                Email:
                                            </div>
                                        </th>
                                        <td>support@hedgepay.org</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="d-flex align-items-center">
                                                <svg className='mr-sm-3 mr-2' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M10.8398 14.625C11.8046 16.6172 13.4155 18.224 15.4102 19.1836C15.5573 19.2533 15.72 19.2835 15.8824 19.2711C16.0447 19.2588 16.201 19.2044 16.3359 19.1133L19.2656 17.1563C19.3951 17.0685 19.5446 17.0149 19.7004 17.0006C19.8561 16.9862 20.013 17.0116 20.1563 17.0743L25.6406 19.4297C25.8281 19.5077 25.9847 19.6452 26.0863 19.821C26.1879 19.9967 26.229 20.201 26.2031 20.4024C26.0293 21.7591 25.3671 23.006 24.3404 23.9098C23.3137 24.8135 21.9928 25.3122 20.625 25.3125C16.3981 25.3125 12.3443 23.6334 9.35549 20.6446C6.36663 17.6557 4.6875 13.6019 4.6875 9.37504C4.68781 8.00722 5.1865 6.68638 6.09026 5.65967C6.99403 4.63296 8.24093 3.97074 9.59766 3.79691C9.79903 3.77105 10.0033 3.81209 10.1791 3.91373C10.3548 4.01538 10.4923 4.17197 10.5703 4.35941L12.9258 9.85551C12.987 9.99654 13.0127 10.1505 13.0004 10.3037C12.9881 10.457 12.9384 10.6049 12.8555 10.7344L10.8984 13.711C10.8113 13.8456 10.7602 14.0003 10.7499 14.1604C10.7397 14.3204 10.7706 14.4804 10.8398 14.625V14.625Z" stroke="white" strokeWidth="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                                Phone:
                                            </div>
                                        </th>
                                        <td>+1(803)563-8489 <br />+6(012)430-5201</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <div className="d-flex align-items-center">
                                                <svg className='mr-sm-3 mr-2' width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15 15.9375C17.0711 15.9375 18.75 14.2586 18.75 12.1875C18.75 10.1164 17.0711 8.4375 15 8.4375C12.9289 8.4375 11.25 10.1164 11.25 12.1875C11.25 14.2586 12.9289 15.9375 15 15.9375Z" stroke="white" strokeWidth="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M24.375 12.1875C24.375 20.625 15 27.1875 15 27.1875C15 27.1875 5.625 20.625 5.625 12.1875C5.625 9.7011 6.61272 7.31653 8.37087 5.55837C10.129 3.80022 12.5136 2.8125 15 2.8125C17.4864 2.8125 19.871 3.80022 21.6291 5.55837C23.3873 7.31653 24.375 9.7011 24.375 12.1875V12.1875Z" stroke="white" strokeWidth="1.68" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>

                                                Location:
                                            </div>
                                        </th>
                                        <td><p>South Carolina <br />United States</p> <p>Kuala Lumpur <br />Malaysia</p></td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p>*Operation hours: 10:00AM - 10:00PM  UTC</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}

export default team;
